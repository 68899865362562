import React, { useState } from "react";
import "./Projects.css";
import { ReactComponent as CloseIcon } from "../Img/symbols_close.svg";
import { useNavigate } from "react-router-dom";
import { gallery } from "./db";
import { Carousel } from "primereact/carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Projects() {
  const navigate = useNavigate();

  const [model, setModel] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);

  const getImg = (proj) => {
    console.log(proj);
    setCurrentProject(proj);
    setModel(true);
  };

  const productTemplate = (imgUrl) => {
    return (
      <div className="carousel-template">
        <LazyLoadImage
          className="carousel-image"
          alt="image" src={imgUrl}
          key={imgUrl}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <div className={model ? "model model-open" : "model"}>
          {model && (
            <div className="card">
              <CloseIcon
                className="model-open-icon"
                onClick={() => setModel(false)}
              />
              <h2 className="text-center">{currentProject.title}</h2>

              <Carousel
                value={currentProject.images}
                numVisible={1}
                numScroll={1}
                circular
                itemTemplate={productTemplate}
              />
              <div>
                <p className="text-center">
                  {currentProject.desclong}. Wykonany w programie:{" "}
                  {currentProject.tech}, dla {currentProject.for}.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="portfolio-content">
        <div className="button-area-top">
          <button
            className="button-first button-second link"
            onClick={() => navigate("/")}
          >
            strona główna
          </button>

          <button className="button-first link" onClick={() => navigate("/cv")}>
            cv
          </button>
        </div>


        <div className={`portfolio-grid`}>
          {gallery.map((item, index) => {
            return (
              <div
                className={`${item.size} item-box`}
                key={index}
                onClick={() => getImg(item)}
              >
                <LazyLoadImage
                  src={item.imgSrc}
                  className="item-image"
                  alt="project"
                  style={{ width: "100%" }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
