import React from "react";
import "./Cv.css";

import { useNavigate } from "react-router-dom";
import cv_1 from "../Img/cv_1.png";
import cv_2 from "../Img/cv_2.png";

import cv_download from "../Img/cv_download.pdf";

export default function Cv() {
  const navigate = useNavigate();

  return (
    <>
      <div className="cv-content">
        <div className="button-area-top">
          <button
            className="cv-button-first cv-button-second cv-link"
            onClick={() => navigate("/")}
          >
            strona główna
          </button>

          <a
            href={cv_download}
            target="_blank"
            className="cv-button-first link cv-button-second"
            rel="noreferrer"
          >
            pobierz cv
          </a>
        </div>

        <div className="cv-max">
          <img src={cv_1} alt="cv" className="cv" />
          <img src={cv_2} alt="cv" className="cv" />
        </div>
      </div>
    </>
  );
}
