import React from "react";
import "./Intro.css";
import arrow from "../Img/arrow-right.svg";
import portrait from "../Img/portrait1.jpg";

import { ReactComponent as LinkedIcon } from "../Img/linkedin.svg";
import { Link, useNavigate } from "react-router-dom";

const Intro = () => {
  const navigate = useNavigate();

  return (
    <div className="intro">
      <div className="intro-left">
        <Link
          to="//www.linkedin.com/in/sylwia-%C5%9Bwierc-bambynek-4b719116a/"
          className="linkedin-container desktop "
          target="_blank"
        >
          <LinkedIcon />
        </Link>
        <div className="intro-left-text">
          <h1>Sylwia</h1>
          <h2>Świerc-Bambynek</h2>
          <div>
            <hr className="intro-left-line" />
          </div>
          <h3>Projektant 3D</h3>
        </div>

        <div className="button-area">
          <button
            className="button-left link"
            onClick={() => navigate("/projects")}
          >
            projekty
          </button>

          <button className="button-left link" onClick={() => navigate("/cv")}>
            cv
          </button>
        </div>
      </div>

      <div className="intro-right">
        <div className="portrait-container">
          <img src={portrait} alt="portrait" className="intro-right-portrait" />
        </div>

        <div className="contact-button-container">

          <Link
            to="//www.linkedin.com/in/sylwia-%C5%9Bwierc-bambynek-4b719116a/"
            className="linkedin-container mobile "
            target="_blank"
          >
            <LinkedIcon />
          </Link>


          <button
            className="button-right link"
            onClick={() => navigate("/contact")}
          >
            <div>Skontaktuj sie ze mną!</div>
            <div>
              <span className="button-right-span">więcej</span>
              <img className="arrow-right" src={arrow} alt="arrow icon" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
