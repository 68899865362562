import React from "react";
import "./Contact.css";

import chairscan from "../Img/panel_exscanhx.png";
import { ReactComponent as LinkedIcon } from "../Img/linkedin.svg";
import { Link, useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();

  return (
    <div className="contact">
      <div className="contact-left">
        <div className="top-container">
          <Link
            to="//www.linkedin.com/in/sylwia-%C5%9Bwierc-bambynek-4b719116a/"
            className="linkedin-container desktop"
            target="_blank"
          >
            <LinkedIcon />
          </Link>

          <button className="button-first link" onClick={() => navigate("/")}>
            strona główna
          </button>
        </div>

        <div className="contact-left-text">
          <h1>Sylwia</h1>
          <h2>Świerc-Bambynek</h2>
          <div>
            <hr className="contact-left-line" />
          </div>

          {/*<h3>UNI design studio</h3>*/}
          {/*  <h3>ul.Sobieskiego 27a</h3>*/}
          {/* <h3>42-286 Koszęcin</h3>*/}

          <div className="mobile-container">
            <h4>tel. 603 491 923</h4>
            <h4>swiercsylwia@gmail.com</h4>
          </div>
        </div>
      </div>

      <div className="contact-right">
        <div className="chairscan-container">
          <img
            src={chairscan}
            alt="scan chair screenshot "
            className="contact-right-chairscan"
          />
        </div>
      </div>
      <Link
        to="//www.linkedin.com/in/sylwia-%C5%9Bwierc-bambynek-4b719116a/"
        className="linkedin-container c-mobile"
        target="_blank"
      >
        <LinkedIcon />
      </Link>
    </div>
  );
};

export default Contact;
