export const gallery = [
  {
    id: 1,
    title: "Kuchnia w bieli",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_7.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_8.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_9.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/38_kuchnia_w_bieli/kuchnia_w_bieli_10.jpg",
   ],
    size: "tall",
    tech: "Sketchup 2023, V-Ray",
    desclong: "projekt kuchni w bieli wg. wytycznych klienta",
    for: "UNI design studio",
    alt: "projekt kuchni",
  },
  {
    id: 2,
    title: "Projekt kuchni",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/39_kuchnia_ryfle/kuchnia_ryfle_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/39_kuchnia_ryfle/kuchnia_ryfle_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/39_kuchnia_ryfle/kuchnia_ryfle_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/39_kuchnia_ryfle/kuchnia_ryfle_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/39_kuchnia_ryfle/kuchnia_ryfle_4.jpg",

    ],
    size: "wide",
    tech: "Sketchup 2023, V-Ray",
    desclong: "projekt jasnej kuchni z frontami fylowanymi wg wytycznych klienta",
    for: "UNI design studio",
    alt: "projekt kuchni",
  },
  {
    id: 3,
    title: "Zabudowa telewizyjna",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/40_zabudowa_telewizyjna/zabudowa_telewizyjna_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/40_zabudowa_telewizyjna/zabudowa_telewizyjna_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/40_zabudowa_telewizyjna/zabudowa_telewizyjna_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/40_zabudowa_telewizyjna/zabudowa_telewizyjna_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/40_zabudowa_telewizyjna/zabudowa_telewizyjna_4.jpg",

    ],
    size: "small",
    tech: "Sketchup 2023, V-Ray",
    desclong: "wizualizacja sceny w pomieszczeniu sali koncertowej",
    for: "UNI design studio",
    alt: "wizualizacja sceny w pomieszczeniu sali koncertowej",
  },
  {
    id: 4,
    title: "Regał na książki",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/41_regal/regal_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/41_regal/regal_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/41_regal/regal_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/41_regal/regal_3.jpg",

    ],
    size: "tall",
    tech: "Sketchup 2023, V-Ray",
    desclong: "Projekt regału na książki",
    for: "UNI design studio",
    alt: "projekt regału",
  },
  {
    id: 5,
    title: "Zadaszenie tarasu",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/42_zadaszenie_taras/zadaszenie_taras_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/42_zadaszenie_taras/zadaszenie_taras_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/42_zadaszenie_taras/zadaszenie_taras_2.jpg",

    ],
    size: "small",
    tech: "Sketchup 2023, V-Ray",
    desclong: "Projekt zadaszenia wraz z aranżacją tarasu",
    for: "UNI design studio",
    alt: "wizualizacja aranżacji tarasu",
  },
  {
    id: 6,
    title: "Schody",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/43_rozbudowa_schodow/rozbudowa_schodow_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/43_rozbudowa_schodow/rozbudowa_schodow_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/43_rozbudowa_schodow/rozbudowa_schodow_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/43_rozbudowa_schodow/rozbudowa_schodow_3.jpg",

    ],
    size: "tall",
    tech: "Sketchup 2023, V-Ray",
    desclong: "Projekt rozbudowy schodów drewnianych",
    for: "UNI design studio",
    alt: "wizualizacja schodów drewnianych",
  },
  {
    id: 7,
    title: "Scena rodzajowa",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/44_stolik_fotel/stolik_fotel_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/44_stolik_fotel/stolik_fotel_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/44_stolik_fotel/stolik_fotel_2.jpg",

    ],
    size: "wide",
    tech: "Sketchup 2023, V-Ray",
    desclong: "wizualizacja sceny wraz z projektem stolika bocznego oraz modelowaniem fotela",
    for: "UNI design studio",
    alt: "wizualizacja sceny z fotelem i stolikiem",
  },
  {
    id: 8,
    title: "Projekt kuchni",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_7.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_8.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/45_kuchnia_mietowa_chmurka/kuchnia_mietowa_chmurka_9.jpg",

    ],
    size: "small",
    tech: "Sketchup 2023, V-Ray",
    desclong: "Projekt kuchni w kolorze miętowej chmurki wg wytycznych klienta",
    for: "UNI design studio",
    alt: "projekt kuchni",
  },
  {
    id: 9,
    title: "Projekt domku letniskowego",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/46_domek_letniskowy/domek_letniskowy_7.jpg",

    ],
    size: "tall",
    tech: "Sketchup 2023, V-Ray",
    desclong: "Projekt i aranżacja domku letniskowego",
    for: "UNI design studio",
    alt: "wizualizacja domku letniskowego",
  },
  {
    id: 10,
    title: "Sala koncertowa",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/47_sala_koncertowa/sala_koncertowa_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/47_sala_koncertowa/sala_koncertowa_1.jpg",

    ],
    size: "tall",
    tech: "Sketchup 2021, V-Ray",
    desclong: "wizualizacja sceny w pomieszczeniu sali koncertowej",
    for: "UNI design studio",
    alt: "wizualizacja sceny w pomieszczeniu sali koncertowej",
  },
  {
    id: 11,
    title: "Kuchnia z jadalnią",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/48_dom_z_wykuszem/dom_z_wykuszem_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/48_dom_z_wykuszem/dom_z_wykuszem_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/48_dom_z_wykuszem/dom_z_wykuszem_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/48_dom_z_wykuszem/dom_z_wykuszem_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/48_dom_z_wykuszem/dom_z_wykuszem_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/48_dom_z_wykuszem/dom_z_wykuszem_5.jpg"

    ],
    size: "tall",
    tech: "Sketchup 2021, V-Ray",
    desclong: "projekt kuchni z jadalnią w stylu glamur", 
    for: "UNI design studio",
    alt: "projekt kuchni z jadalnią",
  },
  {
    id: 12,
    title: "Salon",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/49_parter_dom_kalety/parter_dom_kalety_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/49_parter_dom_kalety/parter_dom_kalety_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/49_parter_dom_kalety/parter_dom_kalety_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/49_parter_dom_kalety/parter_dom_kalety_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/49_parter_dom_kalety/parter_dom_kalety_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/49_parter_dom_kalety/parter_dom_kalety_5.jpg",
    ],
    size: "wide",
    tech: "Sketchup 2021, V-Ray",
    desclong: "projekt salonu  w domu jednorodzinnym",
    for: "UNI design studio",
    alt: "projekt salonu",
  },
  {
    id: 13,
    title: "meble i zabudowy ogrodowe",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/50_meble_ogrodowe/meble_ogrodowe_7.jpg",
  
    ],
    size: "small",
    tech: "Sketchup 2021, V-Ray",
    desclong: "modele 3d mebli i elementów małej architektury ogrodowej. Wizualizacja tarasu.",
    for: "Stolldrew",
    alt: "projekt mebli i zabudowy ogrodowej",
  },

  {
    id: 14,
    title: "Kuchnia",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_3.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_4.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_7.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_8.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/51_kuchnia_w2_kalety/kuchnia_w2_kalety_9.jpg",
    ],
    size: "tall",
    tech: "Sketchup 2021, V-Ray, Sketchup LayOut",
    desclong: "Projekt koncepcyjny i dokumentacja techniczna kuchni",
    for: "UNI design studio",
    alt: "projekt kuchni",
  },
  {
    id: 15,
    title: "Kuchnia",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_3.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_4.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_5.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_7.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_8.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/52_kuchnia_w1_kalety/kuchnia_w1_kalety_9.jpg",
    ],
    size: "wide",
    tech: "Sketchup 2021, V-Ray, Sketchup LayOut",
    desclong: "Projekt koncepcyjny i dokumentacja techniczna kuchni",
    for: "UNI design studio",
    alt: "projekt kuchni",
  },
  {
    id: 16,
    title: "Zabudowa wiatrołapu",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/53_wiatrolap_w1_kalety/wiatrolap_w1_kalety_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/53_wiatrolap_w1_kalety/wiatrolap_w1_kalety_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/53_wiatrolap_w1_kalety/wiatrolap_w1_kalety_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/53_wiatrolap_w1_kalety/wiatrolap_w1_kalety_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/53_wiatrolap_w1_kalety/wiatrolap_w1_kalety_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/53_wiatrolap_w1_kalety/wiatrolap_w1_kalety_5.jpg",
    ],
    size: "small",
    tech: "Sketchup 2021, V-Ray, Sketchup LayOut",
    desclong: "Projekt wstępny zabudowy wiatrołapu, dokumentacja techniczna",
    for: "UNI design studio",
    alt: "projekt zabudowy wiatrołapu",
  },
  {
    id: 17,
    title: "Łazienka",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_3.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_4.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/54_lazienka_w1_kalety/lazienka_w1_kalety_6.jpg",
    ],
    size: "wide",
    tech: "Sketchup 2021, V-Ray, Sketchup LayOut",
    desclong: "Projekt koncepcyjny łazienki, dokumentacja techniczna szafek",
    for: "UNI design studio",
    alt: "projekt łazienki",
  },
  {
    id: 18,
    title: "Kuchnia",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/55_kuchnia_alison_archiinvest/kuchnia_alison_archiinvest_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/55_kuchnia_alison_archiinvest/kuchnia_alison_archiinvest_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/55_kuchnia_alison_archiinvest/kuchnia_alison_archiinvest_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/55_kuchnia_alison_archiinvest/kuchnia_alison_archiinvest_3.png",
    ],
    size: "tall",
    tech: "Sketchup 2021, V-Ray",
    desclong: "Wizualizacja kuchni wg. wytycznych",
    for: "Archi-invest",
    alt: "wizualizacja kuchni",
  },
  {
    id: 19,
    title: "Kuchnia",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_3.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/56_kuchnia_trevor_archiinvest/kuchnia_trevor_archiinvest_6.jpg",
    ],
    size: "tall",
    tech: "Sketchup 2021, V-Ray, Sketchup LayOut",
    desclong: "Wizualizacja kuchni wg. wytycznych, rysunki techniczne",
    for: "Archi-invest",
    alt: "wizualizacja kuchni",
  },
  {
    id: 20,
    title: "Biuro",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/57_biurko_trevor_archiinvest/biurko_trevor_archiinvest_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/57_biurko_trevor_archiinvest/biurko_trevor_archiinvest_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/57_biurko_trevor_archiinvest/biurko_trevor_archiinvest_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/57_biurko_trevor_archiinvest/biurko_trevor_archiinvest_3.png",
    ],
    size: "wide",
    tech: "Sketchup 2021, V-Ray",
    desclong: "Wizualizacja zabudowy biurowej wg. wytycznych",
    for: "Archi-invest",
    alt: "wizualizacja zabudowy biurowej",
  },
  {
    id: 21,
    title: "Szafa",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/58_szafa_trevor_archiinvest/szafa_trevor_archiinvest_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/58_szafa_trevor_archiinvest/szafa_trevor_archiinvest_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/58_szafa_trevor_archiinvest/szafa_trevor_archiinvest_2.png",
    ],
    size: "small",
    tech: "Sketchup 2021, V-Ray",
    desclong: "Wizualizacja zabudowy w sypialni wg. wytycznych",
    for: "Archi-invest",
    alt: "wizualizacja zabudowy w sypialni",
  },
  {
    id: 22,
    title: "Kuchnia",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/59_kuchnia_issam_archiinvest/kuchnia_issam_archiinvest_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/59_kuchnia_issam_archiinvest/kuchnia_issam_archiinvest_1.png",
    ],
    size: "wide",
    tech: "Sketchup 2021, V-Ray",
    desclong: "Wizualizacja kuchni wg. wytycznych",
    for: "Archi-invest",
    alt: "wizualizacja kuchni",
  },
  {
    id: 23,
    title: "Kuchnia",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/60_kuchnia matthew_archiinvest/kuchnia matthew_archiinvest_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/60_kuchnia matthew_archiinvest/kuchnia matthew_archiinvest_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/60_kuchnia matthew_archiinvest/kuchnia matthew_archiinvest_2.png",
    ],
    size: "small",
    tech: "Sketchup 2021, V-Ray",
    desclong: "Wizualizacja kuchni wg. wytycznych",
    for: "Archi-invest",
    alt: "wizualizacja kuchni",
  },




  {
    id: 24,
    title: "Skanowanie 3d",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/61_skan3d/skan3d_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/61_skan3d/skan3d_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/61_skan3d/skan3d_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/61_skan3d/skan3d_3.jpg",
    ],
    size: "tall",
    tech: "EXScan",
    desclong: "Skanowanie mebli i elementów meblowych",
    for: "UNI design studio",
    alt: "modele mebli skanowanych 3d",
  },
  {
    id: 25,
    title: "Kuchnia",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/62_kuchnia/kitchen_furniture_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/62_kuchnia/kitchen_furniture_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/62_kuchnia/kitchen_furniture_2.jpg",
    ],
    size: "tall",
    tech: "Palette Cad, Corel Technical Suit",
    desclong: "Projekt kuchni na wymiar",
    for: "Vision Drafting Services Australia",
    alt: "rysunki techniczne kuchni",
  },
  {
    id: 26,
    title: "Garderoba",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/63_garderoba/garderoba_na_podaszu_1.png",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/63_garderoba/garderoba_na_podaszu_1.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/63_garderoba/garderoba_na_podaszu_2.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/63_garderoba/garderoba_na_podaszu_3.png",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/63_garderoba/garderoba_na_podaszu_4.png",
    ],
    size: "wide",
    tech: "Palette Cad",
    desclong: "Projekt garderoby na wymiar",
    for: "UNI design studio",
    alt: "projekt garderoby na wymiar",
  },
  {
    id: 27,
    title: "Zabudowa telewizyjna",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/64_zabudowa_telewizyjna/zabudowa_telewizyjna_6.jpg",
    ],
    size: "small",
    tech: "Palette Cad",
    desclong:
      "Projekt zabudowy telewizyjnej na wymiar,z wykorzystaniem elementów meblowych kolekcji Savoia",
    for: "Novelle Home Couture",
    alt: "Projekt zabudowy telewizyjnej",
  },
  {
    id: 28,
    title: "Regał",
    imgSrc: "https://jxjbqzagxp.cfolks.pl/port-gallery/65_rergal/regal_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/65_rergal/regal_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/65_rergal/regal_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/65_rergal/regal_3.jpg",
    ],
    size: "small",
    tech: "Palette Cad",
    desclong: "Projekt koncepcyjny regału",
    for: "Novelle Home Couture",
    alt: "Projekt koncepcyjny regału",
  },
  {
    id: 29,
    title: "Mała architektura ogrodowa",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_7.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_8.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_9.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_10.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_11.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_12.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_13.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_14.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_15.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_16.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_17.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_18.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_19.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_20.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_21.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_22.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_23.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_24.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_25.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_26.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_27.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_28.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_29.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/66_mala_architektura_ogrodowa/mala_architektura_ogrodowa_30.jpg",
    ],
    size: "wide",
    tech: "Dietrichs CAD/CAM",
    desclong:
      "Projekty poglądowe małej architektury ogrodowej: zadaszeń, drewutni i domków nzrzędziowych",
    for: "Stolldrew",
    alt: "Projekty małej architektury ogrodowej",
  },
  {
    id: 30,
    title: "Instrukcje złożenia",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/67_instrukcje_zlozenia/instrukcja_zlozenia_domku_z_drewutnia_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/67_instrukcje_zlozenia/instrukcja_zlozenia_domku_z_drewutnia_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/67_instrukcje_zlozenia/instrukcja_zlozenia_domku_z_drewutnia_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/67_instrukcje_zlozenia/instrukcja_zlozenia_domku_z_drewutnia_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/67_instrukcje_zlozenia/instrukcja_zlozenia_domku_z_drewutnia_4.jpg",
    ],
    size: "wide",
    tech: "Corel Technical Suit",
    desclong:
      "Instrukcja do samodzielnego złożenia domku narzędziowego z drewutniami",
    for: "Stolldrew",
    alt: "Instrukcja złożenia domku narzędziowego z drewutniami",
  },
  {
    id: 31,
    title: "Aneks kuchenny",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/68_aneks_domku_letniskowego/aneks_domku_letniskowego_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/68_aneks_domku_letniskowego/aneks_domku_letniskowego_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/68_aneks_domku_letniskowego/aneks_domku_letniskowego_2.jpg",
    ],
    size: "small",
    tech: "Palette Cad",
    desclong: "Projekt aneksu do domku letniskowego",
    for: "UNI design studio",
    alt: "Projekt aneksu do domku letniskowego",
  },
  {
    id: 32,
    title: "Szafka łazienkowa",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/69_szafka_lazienkowa/szafka_lazienkowa_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/69_szafka_lazienkowa/szafka_lazienkowa_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/69_szafka_lazienkowa/szafka_lazienkowa_2.jpg",
    ],
    size: "wide",
    tech: "Palette Cad",
    desclong: "Projekt podwójnej szafki pod umywalki",
    for: "Novelle Home Couture",
    alt: "Projekt podwójnej szafki pod umywalki",
  },
  {
    id: 33,
    title: "Meble tapicerowane",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/70_modelowanie_mebli_tapicerowanych/modelowanie_mebli_tapicerowanych_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/70_modelowanie_mebli_tapicerowanych/modelowanie_mebli_tapicerowanych_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/70_modelowanie_mebli_tapicerowanych/modelowanie_mebli_tapicerowanych_2.jpg",
    ],
    size: "wide",
    tech: "Palette Cad",
    desclong:
      "Modelowanie mebli tapicerowanych na potrzeby zewnętrznych projektantów",
    for: "Novelle Home Couture",
    alt: "Projekt aneksu do domku letniskowego",
  },
  {
    id: 34,
    title: "Domki ogrodowe",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_7.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_8.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/71_domki_ogrodowe/domki_mala_architektura_ogrodowa_9.jpg",
    ],
    size: "tall",
    tech: "Dietrichs CAD/CAM",
    desclong: "Projekty koncepcyjne drewnianych domków ogrodowych",
    for: "Stolldrew",
    alt: "Przykłady domków ogrodowych",
  },
  {
    id: 35,
    title: "Digitalizacja",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/72_modelowanie_mebli/digitalizacja_mebli_7.jpg",
    ],
    size: "small",
    tech: "Palette Cad",
    desclong:
      "Modelowanie/digitalizacja mebli/kolekcji meblowych z uwzględnieniem założeń technicznych produkowanych mebli",
    for: "Novelle Home Couture",
    alt: "Modele 3d mebli",
  },
  {
    id: 36,
    title: "Akcesoria meblowe",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_1.JPG",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_1.JPG",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_2.JPG",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_6.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/73_akcesoria_meblowe/akcesoria_meblowe_dyplom_7.jpg",
    ],
    size: "wide",
    tech: "Solidworks, MasterCam",
    desclong:
      "Projekt zestawu akcesoriów meblowych oraz opracowanie technologii wykonania wybranego elementu z wykorzystaniem systemów CAD/CAM. Praca dyplomowa.",
    for: "Sylwia Świerc-Bambynek",
    alt: "Praca dyplomowa projektu zestawu akcesoriów meblowych",
  },
  {
    id: 37,
    title: "Stoisko wystawowe",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_5.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/74_stoisko_wystawowe/stoisko_wystawowe_6.jpg",
    ],
    size: "tall",
    tech: "Palette Cad",
    desclong:
      "Projekt i dokumentacja techniczno-wykonawcza stoiska wystawowego.",
    for: "Novelle Home Couture",
    alt: "Projekt stoiska wystawowego",
  },
  {
    id: 38,
    title: "Tor OCR",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/75_tor_ocr/tor_ocr_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/75_tor_ocr/tor_ocr_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/75_tor_ocr/tor_ocr_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/75_tor_ocr/tor_ocr_3.jpg",
    ],
    size: "small",
    tech: "Dietrichs CAD/CAM",
    desclong:
      "Projekt i rysunki techniczne konstrukcji drewnianej toru przeszkód",
    for: "Stolldrew",
    alt: "Projekt toru OCR",
  },
  {
    id: 39,
    title: "Dekoracje",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/76_modelowanie_elementow_dekoracyjnych/modelowanie_elementow_dekoracyjnych_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/76_modelowanie_elementow_dekoracyjnych/modelowanie_elementow_dekoracyjnych_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/76_modelowanie_elementow_dekoracyjnych/modelowanie_elementow_dekoracyjnych_2.jpg",
    ],
    size: "small",
    tech: "Palette Cad",
    desclong:
      "Modele elementów dekoracyjnych wnętrz na potrzeby projektantów zewnętrznych",
    for: "Novelle Home Couture",
    alt: "Modele elementów dekoracyjnych wnętrz",
  },
  {
    id: 40,
    title: "Wizualizacja wnętrz",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/77_wizualizacja_wnetrz/wizualizacja_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/77_wizualizacja_wnetrz/wizualizacja_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/77_wizualizacja_wnetrz/wizualizacja_2.jpg",
    ],
    size: "small",
    tech: "Palette Cad",
    desclong: "Wizualizacja wnętrz z wykorzystaniem oferty firmowej",
    for: "Novelle Home Couture",
    alt: "Wizualizacje wnętrz",
  },
  {
    id: 41,
    title: "Plac zabaw",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/78_plac_zabaw/plac_zabaw_mala_architektura_ogrodowa_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/78_plac_zabaw/plac_zabaw_mala_architektura_ogrodowa_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/78_plac_zabaw/plac_zabaw_mala_architektura_ogrodowa_2.jpg",
    ],
    size: "tall",
    tech: "Dietrichs CAD/CAM",
    desclong: "Projekt konstrukcji drewnianej na plac zabaw",
    for: "Stolldrew",
    alt: "Projekty placu zabaw",
  },
  {
    id: 42,
    title: "Instrukcja złożenia",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/79_instrukcje_montazu/instrukcja_złożenia_zabudowy_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/79_instrukcje_montazu/instrukcja_złożenia_zabudowy_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/79_instrukcje_montazu/instrukcja_złożenia_zabudowy_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/79_instrukcje_montazu/instrukcja_złożenia_zabudowy_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/79_instrukcje_montazu/instrukcja_złożenia_zabudowy_4.jpg",
    ],
    size: "wide",
    tech: "CorelDraw Technical Suit",
    desclong:
      "Instrukcja złożenia indywidualnej zabudowy domku narzędziowego z drewutniami do samodzielnego montażu",
    for: "Stolldrew",
    alt: "Instrukcja złożenia zabudowy ogrodowej",
  },
  {
    id: 43,
    title: "Modele mebli",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/80_modelowanie_mebli/modelowanie_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/80_modelowanie_mebli/modelowanie_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/80_modelowanie_mebli/modelowanie_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/80_modelowanie_mebli/modelowanie_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/80_modelowanie_mebli/modelowanie_4.jpg",
    ],
    size: "wide",
    tech: "Palette Cad, Sketchup",
    desclong: "Modelowaie mebli na potrzeby wizualizacji wnętrz",
    for: "Novelle Home Couture",
    alt: "Modelowanie mebli",
  },
  {
    id: 44,
    title: "Foldery informacyjne",
    imgSrc:
      "https://jxjbqzagxp.cfolks.pl/port-gallery/81_foldery_informacyjne/foldery_informacyjne_1.jpg",
    images: [
      "https://jxjbqzagxp.cfolks.pl/port-gallery/81_foldery_informacyjne/foldery_informacyjne_1.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/81_foldery_informacyjne/foldery_informacyjne_2.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/81_foldery_informacyjne/foldery_informacyjne_3.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/81_foldery_informacyjne/foldery_informacyjne_4.jpg",
      "https://jxjbqzagxp.cfolks.pl/port-gallery/81_foldery_informacyjne/foldery_informacyjne_5.jpg",
    ],
    size: "small",
    tech: "Corel Technical Suit",
    desclong:
      "Wykonanie rysunków technicznych i folderów informacyjnych dla klientów na potrzeby strony internetowej",
    for: "Stolldrew",
    alt: "Foldery informacyjne",
  },
];
